@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&family=Open+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');
@import 'react-dropzone-uploader/dist/styles.css';
@import 'react-toastify/dist/ReactToastify.css';




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Background toate paginile */
  background-color: #F6F7F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Futura PT Bold";
  src: url('./fonts/FuturaPT/FuturaPTBold.otf');
}

@font-face {
  font-family: "Raleway Light";
  src: url('./fonts/Raleway/Raleway-Light.ttf');
}

@font-face {
  font-family: "Raleway Medium";
  src: url('./fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: "Raleway Regular";
  src: url('./fonts/Raleway/Raleway-Regular.ttf');
}
@font-face {
  font-family: "Raleway Bold";
  src: url('./fonts/Raleway/Raleway-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: "Raleway SemiBold";
  src: url('./fonts/Raleway/Raleway-SemiBold.ttf');
  font-display: swap;
}

.fc-theme-standard th .fc-day{
  background-color: rgba(99, 104, 124,0.1);
  
}
.fc-col-header > thead > tr{
  border-radius: 20px!important;
}

.fc .fc-timegrid-slot-label{
  border: 0px!important;
}
.mini-calendar .fc-theme-standard th .fc-day{
  background-color: white;
}

.mini-calendar *{
  border: 0px!important;
}

.fc .fc-scrollgrid-liquid{
  border: 0px!important;
}

.mini-calendar .fc-today-button{
  display: none!important;
}

.mini-calendar .fc .fc-button-primary{
  background-color: white;
  color: black;
  font-family: 'Open Sans',sans-serif;
}

.fc .fc-timegrid-col.fc-day{
  background: white;
}

.fc .fc-scrollgrid {
  border: 0px!important;
}
.fc .fc-timegrid-axis{
  background-color: transparent;
}

.fc .fc-col-header colgroup col{
  background-color: #F6F7F8;
}

thead tr th{
  border: 0px!important;
}
.fc-scrollgrid-shrink-cushion{
  font-weight: bold;
  font-size: 13px;
  font-family: 'Open Sans',sans-serif;
  color: #2E324D;
}

.fc thead tr th:nth-child(2){
  border-top-left-radius: 10px;
}
.fc thead tr th:nth-child(8){
  border-top-right-radius: 10px;
}

.fc-timegrid-slots tr:nth-child(2n) td:nth-child(2){
  border: 0px!important;
}

.fc .fc-timegrid-slot{
  height: 35px!important;
}